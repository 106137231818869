import React from 'react';
import { Helmet } from 'react-helmet';
import './Footer.css'; // Import the CSS file for the footer styles

const Footer = () => {
  return (
    <footer className="footer">
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-MP2JL63NHS"></script>
        <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)};
          gtag('js', new Date());

          gtag('config', 'G-MP2JL63NHS');
        `}
        </script>
        <meta name="google-adsense-account" content="ca-pub-1836037034957702"/>
      </Helmet>
      <div className="footer-content">
        <div className="footer-logo">
          <img src="/logo4.png" alt="Logo" />
        </div>
        {/* Contact column */}
        <div className="footer-column">
          <h3 className="footer-heading">CONTACT</h3>
          <p>Phone: +971 (567) 017 979</p>
          <p>Email: info@rohanautomechanics.com</p>
        </div>

        {/* Address column */}
        <div className="footer-column">
          <h3 className="footer-heading">LOCATE</h3>
          <a href='https://www.google.com/maps/place//@25.2337738,55.2775948,111a,54.9y,8.26t/data=!3m1!1e3!4m6!1m5!3m4!2zMjXCsDE0JzAyLjUiTiA1NcKwMTYnMzguNSJF!8m2!3d25.2340278!4d55.2773611?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D' target="_blank" rel="noopener noreferrer" className="location">
            <p>Al Satwa - Dubai, United Arab Emirates</p>
          </a>
        </div>

        {/* Social media column */}
        <div className="footer-column">
          <h3 className="footer-heading">CONNECT</h3>
          <div className="social-links">
            <a href="https://www.facebook.com/profile.php?id=61556985273317" target="_blank" rel="noopener noreferrer">
              <img src="/facebook.png" alt="Facebook" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <img src="/instagram.png" alt="Instagram" />
            </a>
            <a href="https://wa.me/971567017979" target="_blank" rel="noopener noreferrer">
              <img src="/WhatsApp.png" alt="WhatsApp" />
            </a>
          </div>
          <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=vDLv2OvQGgw0iTcU75t0mfJPxA8pmfNPDCsJ3ETCaCwWCdnNecggj2IXcuqK"></script></span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
